/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-dupe-else-if */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-key */
/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-empty-interface */
// import { NextPage, GetStaticPaths } from "next";
import React, { useEffect, useState } from "react";
// import { useRef } from "react";
// import { SitePluginFieldsEnum } from "../../../generated/graphql-types";

// export const getStaticProps: GetStaticProps = async () => {};

// export const getStaticPaths: GetStaticPaths = async (s: any) => {
//   console.log("TESTing");
//   console.log(s);
// };

// export const TypePage: NextPage<TypePageProps> = (type) => {
//   let test = "";
//   test = type;
//   console.log(test);
//   return test.slug;
// };
// export default TypePage;

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { graphql, PageProps } from "gatsby";
import { Hero } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import { Query } from "../../generated/graphql-types";
import classNames from "classnames";
// import { t } from "i18next";
export interface TypePageProps {
  slug: string;
}

const GA_PRESS: React.FC<
  PageProps<
    Pick<
      Query,
      "allGraphCmsGaPress" | "graphCmsSectorPage" | "allGraphCmsSectorPage"
    >
  >
> = ({
  path,
  slug,
  data: {
    allGraphCmsGaPress: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  let router = slug || "";
  let page = 0;
  router = router?.replace("research", "");
  router = router?.replaceAll("-", " ");
  router = router?.trim();
  let title = router;
  title = router.replace(/\s(.)/g, function ($1: string) {
    return $1.toUpperCase();
  });
  title = title.charAt(0).toUpperCase() + title.slice(1);
  router = router?.toLowerCase();
  if (typeof window === "undefined" || !window.document) {
    return;
  }

  if (page == 0) {
    // window.location.href = "../../" + slug;
  }
  let socialHead2 = "";
  let titleForHeading = "";

  const inputRef = React.useRef(null);

  const Image = {
    layout: "constrained",
    backgroundColor: "#282828",
    images: {
      fallback: {
        sizes: "(min-width: 1280px) 1280px, 100vw",
        src: { socialHead2 },
        srcSet: socialHead2,
        sources: [],
        layout: "constrained",
        width: 1280,
      },
    },
    width: 1280,
    height: 720,
  };

  if (titleForHeading == "") {
    titleForHeading = title;
  }
  let regionArray: string[] = [];
  // let checkerArray: string[] = [];
  let seviceArray: string[] = [];
  let industryArray: string[] = [];
  const pageSize = 6;
  let temppage = 0;
  let tmpcurrentpagevalue = 1;

  // const pageNumbers = range(1, totalPages + 1);

  nodes.map((test) => {
    const regions = test.relatedRegions;
    const sectors = test.relatedServices;
    for (let k = 0; k < sectors.length; k++) {
      if (sectors[k].sectorType == "service") {
        if (!seviceArray.includes(sectors[k].sector)) {
          seviceArray.push(sectors[k].sector);
        }
      } else {
        if (!industryArray.includes(sectors[k].sector)) {
          industryArray.push(sectors[k].sector);
        }
      }
    }

    for (let i = 0; i < regions.length; i++) {
      if (!regionArray.includes(regions[i])) {
        regionArray.push(regions[i]);
      }
    }
  });
  function TruncateText({ text, maxWords, classes }) {
    const [truncatedText, setTruncatedText] = useState(text);
    useEffect(() => {
      console.log("text");
      console.log(text);
      if (text) {
        const words = text.split(" ");
        if (words.length > maxWords) {
          const truncated = words.slice(0, maxWords).join(" ");
          setTruncatedText(`${truncated}...`);
        }
      } else {
        setTruncatedText("Sample Text");
      }
    }, [text, maxWords]);

    return <p className={classes}>{truncatedText}</p>;
  }

  // Function to get the screen width
  function getScreenWidth(): number {
    // Check if the 'window' object is available (for server-side rendering)
    if (typeof window !== "undefined") {
      return window.innerWidth;
    } else {
      // Return a default value if 'window' is not available (for server-side rendering)
      return 0;
    }
  }

  // Example usage:
  const screenWidth = getScreenWidth();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [newOption, setNewOption] = useState("");

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue && !selectedOptions.includes(selectedValue)) {
      setSelectedOptions([...selectedOptions, selectedValue]);
    }
  };
  const handleDeleteOption = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
  };

  const itemsPerPage = 6; // Number of items to display per page
  // Your array of data (divs in this case)
  // const filteredData = nodes.filter((test) => test.mostPopular !== true);
  const [filteredData, setFilteredData] = useState(
    nodes.filter((test) => test.mostPopular !== true)
  );
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Create an array of page numbers
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );
  const [data, setData] = useState(filteredData);
  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleFilterChange = (event) => {
    const tempfilter = nodes.filter(
      (node) =>
        node.relatedServices.some((service) =>
          selectedOptions.includes(service.sector)
        ) ||
        node.relatedRegions.some(
          (region) => selectedOptions.includes(region) // Replace selectedRegions with your selected regions array
        )
    );
    setData(tempfilter);
    setFilteredData(tempfilter);
    setCurrentPage(1);
  };

  if (!GA_PRESS) return null;

  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title={"Press Articles | Partners in Performance"}
      description={sectorPage?.description}
    >
      <Hero
        title={"Press Articles | Partners in Performance"}
        image={Image}
        colour="MediumBlue"
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <div className="grid page-grid lg:grid-cols-4 md:grid-cols-1 grid-cols-1 relative w-full padding8_5">
        <div className="col-span-3 lg:col-span-3">
          <div className="">
            <select
              id="serviceDropDown"
              ref={inputRef}
              // className="lg:w-desktop-3-cols m-10px w-full"
              className="col-span-4 border-none m-10px"
              // className="m-10px px-4 py-2 border  shadow-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
              onChange={handleDropdownChange}
              value={newOption}
            >
              <option value="">Select Service</option>
              <optgroup label="Services">
                {seviceArray.sort().map((servicevalue) => (
                  <option value={servicevalue}>
                    {servicevalue.replaceAll("_", " ")}
                  </option>
                ))}
              </optgroup>
              <optgroup label="Industries">
                {industryArray.sort().map((industryvalue) => (
                  <option value={industryvalue}>
                    {industryvalue.replaceAll("_", " ")}
                  </option>
                ))}
              </optgroup>
            </select>
            <select
              id="regionDropDown"
              // className="lg:w-desktop-3-cols m-10px w-full"
              className="col-span-4 border-none m-10px"
              // className=" m-10px px-4 py-2 border shadow-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
              ref={inputRef}
              onChange={handleDropdownChange}
              value={newOption}
            >
              <option value="">Select Region</option>
              <option value="Africa">Africa</option>
              <option value="Australia_and_New_Zealand">
                Australia and New Zealand
              </option>
              {/* <option value="Brazil">Brazil</option> */}
              {/* <option value="Central_Asia">Central Asia</option> */}
              <option value="Europe">Europe</option>
              <option value="Latin_America">Latin America</option>
              {/* <option value="Middle_East">Middle East</option> */}
              <option value="North_America">North America</option>
              {/* {regionArray.map((regionvalue) => (
                <option value={regionvalue}>
                  {regionvalue.replaceAll("_", " ")}
                </option>
              ))} */}
            </select>
            <button className="underline1 ml-10px" onClick={handleFilterChange}>
              Filter
            </button>
            <a className="underline2 ml-10px" href="/en/press-v1">
              Show all
            </a>
            <div className="">
              <ul className="mt-2 flex flex-wrap">
                {selectedOptions.map((option, index) => (
                  <li
                    key={index}
                    className="flex items-center bg-purple p-10px rounded-full text-white-1 m-10px"
                  >
                    <span className="mr-2">{option}</span>
                    <button
                      className="text-red-500"
                      onClick={() => handleDeleteOption(index)}
                    >
                      <span className="ml-10px p-0 rounded-lg">x</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            {/* <button className="underline1 ml-10px" onClick={buttonHandler}> */}
          </div>
          <div className="bg-white">
            <div
              className={classNames(
                "grid grid-cols-1 sm:grid-cols-1  gap-4",
                screenWidth > 1366 ? "lg:grid-cols-3" : "lg:grid-cols-2"
              )}
            >
              {getPaginatedData().map((test, index) => {
                if (test.mostPopular != true) {
                  if (temppage == pageSize) {
                    temppage = 0;
                    tmpcurrentpagevalue++;
                  } else {
                    temppage++;
                  }
                  return (
                    <a
                      key={index}
                      href={"/en/" + test.fullArticleLink}
                      className={classNames(
                        "page-" + tmpcurrentpagevalue,
                        "hidden"
                        // currentPage == setpage ? "" : "hidden"
                      )}
                    >
                      <div className="bg-white-1 rounded-lg  lg:m-15px md:m-10px m-10px">
                        <img
                          src={test.thumbnail.url}
                          alt="Image 1"
                          className="w-full ga-h200px rounded-tl-lg rounded-tr-lg"
                        />
                        <div className="p-30px">
                          <div className="text-14 text-gray mb-2 h-20px">
                            <span className="post-cat text-green">
                              {test.relatedServices.map((values) => {
                                return values.sector + " ";
                              })}
                            </span>
                          </div>
                          <TruncateText
                            text={test.articleTitle}
                            maxWords={10}
                            classes="h4 font-semibold mb-10px h-60px"
                          />
                          <TruncateText
                            text={
                              test.articleContent.cleaned[0].children[0].text
                            }
                            maxWords={25}
                            classes="font-light text-24 mb-10px press-content-height"
                          />
                          <div className="flex justify-between text-14 text-grey">
                            <p>
                              {new Date(test.publishingDate)
                                .toLocaleString("en-US", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })
                                .toUpperCase()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                }
              })}
            </div>
            <div></div>
          </div>
        </div>
        <div className="col-span-1 lg:col-span-1">
          <br></br>
          <br></br>
          <h3 className="h3 m-15px"> Featured</h3>
          {nodes.map((test) => {
            if (test.mostPopular == true) {
              return (
                <a href={"/en/" + test.fullArticleLink}>
                  <div className="bg-white-1 rounded-lg  lg:m-15px  m-10px">
                    <div className="p-30px">
                      <div className="grid page-grid grid-cols-3 lg:grid-cols-4 relative w-full">
                        <div className="col-span-2 lg:col-span-2">
                          <TruncateText
                            text={test.articleTitle}
                            maxWords={15}
                            classes="h4 font-semibold mb-10px"
                          />
                          <div className="flex justify-between text-14 text-grey">
                            <p>
                              {new Date(test.publishingDate)
                                .toLocaleString("en-US", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                })
                                .toUpperCase()}
                            </p>
                          </div>
                        </div>
                        <div className="col-span-1 lg:col-span-2">
                          <img
                            src={test.thumbnail.url}
                            alt="Image 1"
                            className="ga-w170px h-100px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              );
            }
          })}
        </div>
      </div>
      <center>
        <div className="m-20px">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-button"
          >
            &lt;
          </button>
          {pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`pagination-button ${
                currentPage === pageNumber ? "active" : ""
              }`}
            >
              {pageNumber}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= data.length}
            className="pagination-button"
          >
            &gt;
          </button>
        </div>
      </center>
    </Layout>
  );
};

export default GA_PRESS;

export const IndexQuery = graphql`
  {
    allGraphCmsGaPress(
      filter: { stage: { eq: PUBLISHED }, locale: { eq: en } }
      limit: 88
      sort: { fields: publishingDate, order: DESC }
    ) {
      nodes {
        mostPopular
        articleContent {
          cleaned
        }
        articleTitle
        createdAt
        fullArticleLink
        gaAuthors {
          authorName
          authorLinks
        }
        sourceLink
        relatedRegions
        thumbnail {
          url
        }
        publishingDate
        relatedServices {
          sectorType
          sector
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
    allGraphCmsSectorPage {
      edges {
        node {
          sector
          heroImage {
            id
            url
          }
          pageTitle
        }
      }
    }
  }
`;
